<template>
  <LinearCarousel :mouseDrag="false" ref="myCarousel"
                  :modelValue="modelValue"
                  @loop="currentIndex= $event"
                  :breakpoints="breakpoints"
                  @slide-end="$emit('change',$event)"
                  class="linear-carousel"
                  :wrap-around="$device.isDesktop ? (infinity && items.length > itemsToShow) : infinity"
                  :items-to-show="itemsToShowNumber" :snap-align="'start'" :itemsToScroll="itemsToScroll">
    <LinearSlide v-for="(item,index) in items" :slideWidth="slideWidth">
      <slot :item="item" :index="index"/>
    </LinearSlide>
    <template v-if="!noArrows && items.length > itemsToShow" #addons="params">
      <button class="swiper-btn" v-if="params.currentSlide > 0" @click="$refs.myCarousel.prev()">
        <img src="/images/moves/arrow-white.png"/>
      </button>
      <button class="swiper-btn right" @click="$refs.myCarousel.next()">
        <img src="/images/moves/arrow-white.png"/>
      </button>

<!--      <Pagination/>-->
    </template>
  </LinearCarousel>
</template>

<script>
// import {Pagination} from "vue3-carousel";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    slideWidth: {
      type: Number,
      default: 400,
    },
    itemsToScroll: {
      type: Number,
      default: 4,
    },
    itemsToShow: {
      type: Number,
      default: 4,
    },
    itemsToShowMobile: {
      type: Number,
    },
    itemsToShowTablet: {
      type: Number,
    },
    noArrows: {
      type: Boolean,
      default: false
    },
    infinity: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Number,
      default: 0
    },
    customBreakpoints: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      currentIndex: 0,
      breakpoints: this.customBreakpoints ?? {
        0: {
          itemsToShow: this.itemsToShowMobile ?? this.itemsToShow,
        },
        642: {
          itemsToShow: this.itemsToShowTablet ?? this.itemsToShowMobile ?? this.itemsToShow,
        },
        1281: {
          itemsToShow: this.itemsToShowMobile && this.itemsToShowMobile !== 1 ? this.itemsToShowMobile * 2 : this.itemsToShow,
        },
        1500: {
          itemsToShow: this.itemsToShow,
        },
      }
    }
  },
  // components: {
  //   Pagination
  // },
  computed: {
    itemsToShowNumber() {
      switch (true) {
        case this.$device.isDesktop:
          return this.itemsToShow
        case this.$device.isTablet:
          return this.itemsToShowTablet || this.itemsToShow
        default:
          return this.itemsToShowMobile || this.itemsToShow
      }
    },
    itemsToScrollMobile() {
      return this.itemsToShowMobile || this.itemsToScroll
    }
  }
}
</script>

<style scoped lang="scss">
.swiper-btn {
  left: 0;
  top: 50%;
  border: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 32px 48px rgba(82, 82, 82, .08);
  position: absolute;
  cursor: pointer;
  z-index: 9;
  margin-top: -30px;
  height: 48px;
  width: 48px;
  background: rgba(34, 34, 34, .9);

  @include smd {
    display: none;
  }

  &:hover {
    background: rgba(34, 34, 34, .8)
  }

  img {
    transform: rotate(180deg);
    width: 7px;
  }
}

.swiper-btn.right {
  left: unset;
  right: 0;

  img {
    transform: rotate(0deg);
  }
}

.linear-carousel:deep(.carousel__pagination) {
  height: 2px;
  overflow: hidden;
  width: 99%;
}

.linear-carousel:deep(.carousel__pagination-button) {
  padding: 0;
}

.linear-carousel:deep(.carousel__pagination-item) {
  width: 100%;

  button {
    width: 100%;

    &:after {
      width: 100%;
      background: #EFEFEF;
    }

    &.carousel__pagination-button--active {
      &:after {
        background: $black;
      }
    }
  }
}
</style>
